import React from 'react';
import { css } from 'emotion';

type IconProps = {
  width?: number;
  height?: number;
  className?: string;
  label: string;
};

const srOnly = css({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: '0',
});

export const Bold = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1782 1792" {...rest}>
    <path d="M747 1521q74 32 140 32 376 0 376-335 0-114-41-180-27-44-61.5-74t-67.5-46.5-80.5-25-84-10.5-94.5-2q-73 0-101 10 0 53-.5 159t-.5 158q0 8-1 67.5t-.5 96.5 4.5 83.5 12 66.5zm-14-746q42 7 109 7 82 0 143-13t110-44.5 74.5-89.5 25.5-142q0-70-29-122.5t-79-82T979 245t-124-14q-50 0-130 13 0 50 4 151t4 152q0 27-.5 80t-.5 79q0 46 1 69zm-541 889l2-94q15-4 85-16t106-27q7-12 12.5-27t8.5-33.5 5.5-32.5 3-37.5.5-34V1297q0-982-22-1025-4-8-22-14.5t-44.5-11-49.5-7-48.5-4.5-30.5-3l-4-83q98-2 340-11.5t373-9.5q23 0 68 .5t68 .5q70 0 136.5 13t128.5 42 108 71 74 104.5 28 137.5q0 52-16.5 95.5t-39 72T1398 722t-73 45-84 40q154 35 256.5 134t102.5 248q0 100-35 179.5t-93.5 130.5-138 85.5T1170 1633t-176 14q-44 0-132-3t-132-3q-106 0-307 11t-231 12z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Italic = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1782 1792" {...rest}>
    <path d="M384 1662l17-85q22-7 61.5-16.5t72-19T594 1518q28-35 41-101 1-7 62-289t114-543.5T863 288v-25q-24-13-54.5-18.5t-69.5-8-58-5.5l19-103q33 2 120 6.5t149.5 7T1090 144q48 0 98.5-2.5t121-7 98.5-6.5q-5 39-19 89-30 10-101.5 28.5T1179 279q-8 19-14 42.5t-9 40-7.5 45.5-6.5 42q-27 148-87.5 419.5T977 1224q-2 9-13 58t-20 90-16 83.5-6 57.5l1 18q17 4 185 31-3 44-16 99-11 0-32.5 1.5t-32.5 1.5q-29 0-87-10t-86-10q-138-2-206-2-51 0-143 9t-121 11z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Quote = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1782 1792" {...rest}>
    <path d="M832 960v384q0 80-56 136t-136 56H256q-80 0-136-56t-56-136V640q0-104 40.5-198.5T214 278t163.5-109.5T576 128h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V640q0-104 40.5-198.5T1110 278t163.5-109.5T1472 128h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const List = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M256 1312v192q0 13-9.5 22.5T224 1536H32q-13 0-22.5-9.5T0 1504v-192q0-13 9.5-22.5T32 1280h192q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T224 1152H32q-13 0-22.5-9.5T0 1120V928q0-13 9.5-22.5T32 896h192q13 0 22.5 9.5T256 928zm0-384v192q0 13-9.5 22.5T224 768H32q-13 0-22.5-9.5T0 736V544q0-13 9.5-22.5T32 512h192q13 0 22.5 9.5T256 544zm1536 768v192q0 13-9.5 22.5t-22.5 9.5H416q-13 0-22.5-9.5T384 1504v-192q0-13 9.5-22.5t22.5-9.5h1344q13 0 22.5 9.5t9.5 22.5zM256 160v192q0 13-9.5 22.5T224 384H32q-13 0-22.5-9.5T0 352V160q0-13 9.5-22.5T32 128h192q13 0 22.5 9.5T256 160zm1536 768v192q0 13-9.5 22.5t-22.5 9.5H416q-13 0-22.5-9.5T384 1120V928q0-13 9.5-22.5T416 896h1344q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 768H416q-13 0-22.5-9.5T384 736V544q0-13 9.5-22.5T416 512h1344q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 384H416q-13 0-22.5-9.5T384 352V160q0-13 9.5-22.5T416 128h1344q13 0 22.5 9.5t9.5 22.5z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const ListOl = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M381 1620q0 80-54.5 126T191 1792q-106 0-172-66l57-88q49 45 106 45 29 0 50.5-14.5T254 1626q0-64-105-56l-26-56q8-10 32.5-43.5t42.5-54 37-38.5v-1q-16 0-48.5 1t-48.5 1v53H32v-152h333v88l-95 115q51 12 81 49t30 88zm2-627v159H21q-6-36-6-54 0-51 23.5-93T95 937t66-47.5 56.5-43.5 23.5-45q0-25-14.5-38.5T187 749q-46 0-81 58l-85-59q24-51 71.5-79.5T198 640q73 0 123 41.5T371 794q0 50-34 91.5T262 950t-75.5 50.5T151 1053h127v-60h105zm1409 319v192q0 13-9.5 22.5t-22.5 9.5H544q-13 0-22.5-9.5T512 1504v-192q0-14 9-23t23-9h1216q13 0 22.5 9.5t9.5 22.5zM384 413v99H49v-99h107q0-41 .5-121.5T157 170v-12h-2q-8 17-50 54l-71-76L170 9h106v404h108zm1408 387v192q0 13-9.5 22.5t-22.5 9.5H544q-13 0-22.5-9.5T512 992V800q0-14 9-23t23-9h1216q13 0 22.5 9.5t9.5 22.5zm0-512v192q0 13-9.5 22.5T1760 512H544q-13 0-22.5-9.5T512 480V288q0-13 9.5-22.5T544 256h1216q13 0 22.5 9.5t9.5 22.5z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Link = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zM817 511q0-40-28-68L583 236q-28-28-68-28-39 0-68 27L300 381q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5T607.5 680t-15-19-13-25.5T576 608q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84L164 652q-84-84-84-204t85-203L312 99q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Heading = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1682 1664q-44 0-132.5-3.5T1416 1657q-44 0-132 3.5t-132 3.5q-24 0-37-20.5t-13-45.5q0-31 17-46t39-17 51-7 45-15q33-21 33-140l-1-391q0-21-1-31-13-4-50-4H560q-38 0-51 4-1 10-1 31l-1 371q0 142 37 164 16 10 48 13t57 3.5 45 15 20 45.5q0 26-12.5 48t-36.5 22q-47 0-139.5-3.5T387 1657q-43 0-128 3.5t-127 3.5q-23 0-35.5-21T84 1598q0-30 15.5-45t36-17.5 47.5-7.5 42-15q33-23 33-143l-1-57V500q0-3 .5-26t0-36.5T256 399t-3.5-42-6.5-36.5-11-31.5-16-18q-15-10-45-12t-53-2-41-14-18-45q0-26 12-48t36-22q46 0 138.5 3.5T387 135q42 0 126.5-3.5T640 128q25 0 37.5 22t12.5 48q0 30-17 43.5T634.5 256t-49.5 4-43 13q-35 21-35 160l1 320q0 21 1 32 13 3 39 3h699q25 0 38-3 1-11 1-32l1-320q0-139-35-160-18-11-58.5-12.5t-66-13T1102 198q0-26 12.5-48t37.5-22q44 0 132 3.5t132 3.5q43 0 129-3.5t129-3.5q25 0 37.5 22t12.5 48q0 30-17.5 44t-40 14.5-51.5 3-44 12.5q-35 23-35 161l1 943q0 119 34 140 16 10 46 13.5t53.5 4.5 41.5 15.5 18 44.5q0 26-12 48t-36 22z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Zen = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1411 541l-355 355 355 355 144-144q29-31 70-14 39 17 39 59v448q0 26-19 45t-45 19h-448q-42 0-59-40-17-39 14-69l144-144-355-355-355 355 144 144q31 30 14 69-17 40-59 40H192q-26 0-45-19t-19-45v-448q0-42 40-59 39-17 69 14l144 144 355-355-355-355-144 144q-19 19-45 19-12 0-24-5-40-17-40-59V192q0-26 19-45t45-19h448q42 0 59 40 17 39-14 69L541 381l355 355 355-355-144-144q-31-30-14-69 17-40 59-40h448q26 0 45 19t19 45v448q0 42-39 59-13 5-25 5-26 0-45-19z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Strikethrough = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1760 896q14 0 23 9t9 23v64q0 14-9 23t-23 9H32q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h1728zM483 832q-28-35-51-80-48-98-48-188 0-181 134-309 133-127 393-127 50 0 167 19 66 12 177 48 10 38 21 118 14 123 14 183 0 18-5 45l-12 3-84-6-14-2q-50-149-103-205-88-91-210-91-114 0-182 59-67 58-67 146 0 73 66 140t279 129q69 20 173 66 58 28 95 52H483zm507 256h411q7 39 7 92 0 111-41 212-23 56-71 104-37 35-109 81-80 48-153 66-80 21-203 21-114 0-195-23l-140-40q-57-16-72-28-8-8-8-22v-13q0-108-2-156-1-30 0-68l2-37v-44l102-2q15 34 30 71t22.5 56 12.5 27q35 57 80 94 43 36 105 57 59 22 132 22 64 0 139-27 77-26 122-86 47-61 47-129 0-84-81-157-34-29-137-71z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Code = ({ label, ...rest }: IconProps) => (
  <svg width={18} height={14} viewBox="0 0 2048 1792" {...rest}>
    <path d="M681 1399l-50 50q-10 10-23 10t-23-10L119 983q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23L288 960l393 393q10 10 10 23t-10 23zm591-1067L899 1623q-4 13-15.5 19.5T860 1645l-62-17q-13-4-19.5-15.5T776 1588l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const HR = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1792 896q0 26-19 45l-256 256q-19 19-45 19t-45-19-19-45v-128H384v128q0 26-19 45t-45 19-45-19L19 941Q0 922 0 896t19-45l256-256q19-19 45-19t45 19 19 45v128h1024V640q0-26 19-45t45-19 45 19l256 256q19 19 19 45z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Indent = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M352 832q0 14-9 23L55 1143q-9 9-23 9-13 0-22.5-9.5T0 1120V544q0-13 9.5-22.5T32 512q14 0 23 9l288 288q9 9 9 23zm1440 480v192q0 13-9.5 22.5t-22.5 9.5H32q-13 0-22.5-9.5T0 1504v-192q0-13 9.5-22.5T32 1280h1728q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5H672q-13 0-22.5-9.5T640 1120V928q0-13 9.5-22.5T672 896h1088q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 768H672q-13 0-22.5-9.5T640 736V544q0-13 9.5-22.5T672 512h1088q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 384H32q-13 0-22.5-9.5T0 352V160q0-13 9.5-22.5T32 128h1728q13 0 22.5 9.5t9.5 22.5z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Dedent = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M384 544v576q0 13-9.5 22.5T352 1152q-14 0-23-9L41 855q-9-9-9-23t9-23l288-288q9-9 23-9 13 0 22.5 9.5T384 544zm1408 768v192q0 13-9.5 22.5t-22.5 9.5H32q-13 0-22.5-9.5T0 1504v-192q0-13 9.5-22.5T32 1280h1728q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5H672q-13 0-22.5-9.5T640 1120V928q0-13 9.5-22.5T672 896h1088q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 768H672q-13 0-22.5-9.5T640 736V544q0-13 9.5-22.5T672 512h1088q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5T1760 384H32q-13 0-22.5-9.5T0 352V160q0-13 9.5-22.5T32 128h1728q13 0 22.5 9.5t9.5 22.5z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Cubes = ({ label, ...rest }: IconProps) => (
  <svg width={18} height={14} viewBox="0 0 2304 1792" {...rest}>
    <path d="M640 1632l384-192v-314l-384 164v342zm-64-454l404-173-404-173-404 173zm1088 454l384-192v-314l-384 164v342zm-64-454l404-173-404-173-404 173zm-448-293l384-165V454l-384 164v267zm-64-379l441-189-441-189-441 189zm1088 518v416q0 36-19 67t-52 47l-448 224q-25 14-57 14t-57-14l-448-224q-4-2-7-4-2 2-7 4l-448 224q-25 14-57 14t-57-14L71 1554q-33-16-52-47t-19-67v-416q0-38 21.5-70T78 906l434-186V320q0-38 21.5-70t56.5-48l448-192q23-10 50-10t50 10l448 192q35 16 56.5 48t21.5 70v400l434 186q36 16 57 48t21 70z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Table = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M576 1376v-192q0-14-9-23t-23-9H224q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm0-384V800q0-14-9-23t-23-9H224q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm512 384v-192q0-14-9-23t-23-9H736q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zM576 608V416q0-14-9-23t-23-9H224q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm512 384V800q0-14-9-23t-23-9H736q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm512 384v-192q0-14-9-23t-23-9h-320q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm-512-768V416q0-14-9-23t-23-9H736q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm512 384V800q0-14-9-23t-23-9h-320q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm0-384V416q0-14-9-23t-23-9h-320q-14 0-23 9t-9 23v192q0 14 9 23t23 9h320q14 0 23-9t9-23zm128-320v1088q0 66-47 113t-113 47H224q-66 0-113-47t-47-113V288q0-66 47-113t113-47h1344q66 0 113 47t47 113z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const SpecialChar = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1360 1307l35 159q3 12-3 22.5t-17 14.5l-5 1q-4 2-10.5 3.5t-16 4.5-21.5 5.5-25.5 5-30 5-33.5 4.5-36.5 3-38.5 1q-234 0-409-130.5T511 1054h-95q-13 0-22.5-9.5T384 1022V909q0-13 9.5-22.5T416 877h66q-2-57 1-105h-67q-14 0-23-9t-9-23V626q0-14 9-23t23-9h98q67-210 243.5-338T1158 128q102 0 194 23 11 3 20 15 6 11 3 24l-43 159q-3 13-14 19.5t-24 2.5l-4-1q-4-1-11.5-2.5L1261 364l-22.5-3.5-26-3-29-2.5-29.5-1q-126 0-226 64T778 594h468q16 0 25 12 10 12 7 26l-24 114q-5 26-32 26H734q-3 37 0 105h459q15 0 25 12 9 12 6 27l-24 112q-2 11-11 18.5t-20 7.5H782q48 117 149.5 185.5T1160 1308q18 0 36-1.5t33.5-3.5 29.5-4.5 24.5-5 18.5-4.5l12-3 5-2q13-5 26 2 12 7 15 21z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const OrgLinks = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1792 1248v320q0 40-28 68t-68 28h-320q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h96V960H960v192h96q40 0 68 28t28 68v320q0 40-28 68t-68 28H736q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h96V960H320v192h96q40 0 68 28t28 68v320q0 40-28 68t-68 28H96q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h96V960q0-52 38-90t90-38h512V640h-96q-40 0-68-28t-28-68V224q0-40 28-68t68-28h320q40 0 68 28t28 68v320q0 40-28 68t-68 28h-96v192h512q52 0 90 38t38 90v192h96q40 0 68 28t28 68z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Undo = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1664 896q0 156-61 298t-164 245-245 164-298 61q-172 0-327-72.5T305 1387q-7-10-6.5-22.5t8.5-20.5l137-138q10-9 25-9 16 2 23 12 73 95 179 147t225 52q104 0 198.5-40.5T1258 1258t109.5-163.5T1408 896t-40.5-198.5T1258 534t-163.5-109.5T896 384q-98 0-188 35.5T548 521l137 138q31 30 14 69-17 40-59 40H192q-26 0-45-19t-19-45V256q0-42 40-59 39-17 69 14l130 129q107-101 244.5-156.5T896 128q156 0 298 61t245 164 164 245 61 298z" />
    <span className={srOnly}>{label}</span>
  </svg>
);

export const Redo = ({ label, ...rest }: IconProps) => (
  <svg width={14} height={14} viewBox="0 0 1792 1792" {...rest}>
    <path d="M1664 256v448q0 26-19 45t-45 19h-448q-42 0-59-40-17-39 14-69l138-138q-148-137-349-137-104 0-198.5 40.5T534 534 424.5 697.5 384 896t40.5 198.5T534 1258t163.5 109.5T896 1408q119 0 225-52t179-147q7-10 23-12 15 0 25 9l137 138q9 8 9.5 20.5t-7.5 22.5q-109 132-264 204.5T896 1664q-156 0-298-61t-245-164-164-245-61-298 61-298 164-245 245-164 298-61q147 0 284.5 55.5T1425 340l130-129q29-31 70-14 39 17 39 59z" />
    <span className={srOnly}>{label}</span>
  </svg>
);
